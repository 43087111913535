import React from 'react';
import './ContactUsCard.css';

const ContactUsCard = () => {
  return (
    <div className="about-us-card">
      <h1>Get in Touch.</h1>
      <p>
        We are a team of dedicated professionals committed to providing exceptional services. 
        Reach out to us for any inquiries or assistance. We're here to help!
      </p>
      <a href="mailto:contact@yourcompany.com" target="_blank" className="contact-link">
        Email: support@thesneakercave.com
      </a>
      <a href="https://t.me/+4fxybghXqF1iOTQ0" target="_blank" className="contact-link">
        Telegram Group: @TheSneakerCave
      </a>
      <a href="https://wa.me/447453347455" target="_blank">
        Whatsapp Group: +44 7453347455
      </a>
    </div>
  );
}

export default ContactUsCard;
