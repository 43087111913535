import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { positions, transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import store from "./store";
import App from "./App";
import { BrowserRouter } from "react-router-dom"; 
import { createRoot } from 'react-dom/client';
import { initFacebookPixel } from "./constants/facebookPixel";
import CookieConsent from "react-cookie-consent";  // Import CookieConsent

const theme = createTheme();
const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
  transition: transitions.SCALE,
};
const container = document.getElementById('root');
const root = createRoot(container);

root.render( 
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
          <CookieConsent
            location="bottom"
            buttonText="I understand"
            declineButtonText="I decline"
            enableDeclineButton
            style={{ background: "#2B373B" }}
            buttonStyle={{ fontSize: "13px" }}
            declineButtonStyle={{ fontSize: "13px" }}
            onAccept={() => {
              console.log("Cookies accepted");
              // Add any action you want to perform on acceptance
            }}
            onDecline={() => {
              console.log("Cookies declined");
              // Add any action you want to perform on decline
            }}
          >
            This website uses cookies to enhance the user experience.{" "}
            <a href="/gdpr" style={{ color: "#fff" }}>Learn more</a>
          </CookieConsent>
        </AlertProvider>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);
