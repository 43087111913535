import React from "react";
import {Link} from "react-router-dom";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <MetaData title={"Privacy Policy"} />
      <div className="container___">
        <h2>EXCHANGE</h2>
        <p>The shipping costs for both directions are covered by the customer.</p>
        <h3>Procedure:</h3>
        <ol>
          <li>
            You need to notify us in advance that you want to make an exchange via email – <a href="mailto:support@thesneakercave.com">support@thesneakercave.com</a>
            <ul>
              <li>Please do not send a package to us before we have confirmed that we can make the exchange.</li>
              <li>The Sneaker Cave is not obligated to exchange products that cannot be exchanged due to lack of availability of the desired products!</li>
            </ul>
          </li>
          <li>
            You need to send the package to the address agreed upon when contacting our team.
            <ul>
              <li>Please pack the shipment in its original packaging to protect it from contamination or damage during delivery to us.</li>
            </ul>
          </li>
        </ol>
        <p>We only exchange products that meet the following requirements:</p>
        <ul>
          <li>The product has not been worn or washed</li>
          <li>The product is not damaged and has no defects</li>
          <li>The product is returned in its original packaging, along with all tags, in the same condition as it was received.</li>
        </ul>
        <p>Please note that the dispatch of the new shipment occurs within 3 days after processing the returned shipment.</p>

        <h2>RETURN</h2>
        <p>The shipping costs are covered by the customer.</p>
        <h3>Procedure:</h3>
        <ol>
          <li>You need to notify us in advance that you want to return items for a refund via email – <a href="mailto:support@thesneakercave.com">support@thesneakercave.com</a></li>
          <li>You need to send the package to the address agreed upon when contacting our team.</li>
        </ol>

        <h3>IMPORTANT INFORMATION:</h3>
        <p>The user has the right to return the purchased goods within 14 working days from the date of receipt. The product must be in its original packaging, without any signs of use or violation of the commercial appearance of the product in accordance with the provisions of Art. 55(1) of the Consumer Protection Act. After verification within 14 days, the buyer, who bears all transport costs, will receive a refund.</p>

        <h3>Requirements for both procedures:</h3>
        <ol>
          <li>The exchange or complaint must occur within 14 days from the date of receipt of the shipment.</li>
          <li>Products subject to return or exchange are those that have not undergone any processing (washing, ironing, cutting, and other actions that violate their integrity).</li>
          <li>All shipments are opened before the refund is made. Once we confirm that the shipment meets the specified conditions, the amount will be refunded to you within 3 working days via postal money order. To receive the money order at the courier's office, you will only need an ID card. If the described conditions are not met, the complaint request will not be considered.</li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
