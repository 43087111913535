import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layouts/MataData/MataData";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import OrderDetailsSection from "./OrderDetails";
import { clearErrors, createOrder } from "../../actions/orderAction";
import CheckoutSteps from "./CheckoutSteps ";

// for cardDetails for card detials input section and hooks for accessing strip and element from App.js route
import "./Cart.css";
import {
  Typography,
  TextField,
  Grid,
  Radio,
  Button,
  Divider,
  Link,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  CreditCard,
  CardMembership,
  Payment,
  Lock,

} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import AssuredWorkloadOutlinedIcon from "@mui/icons-material/AssuredWorkloadOutlined";
import { ReactComponent as MasterCard } from "../../Image/payment-svg/mastercard.svg";
import { ReactComponent as Visa } from "../../Image/payment-svg/visa (1).svg";
import { ReactComponent as Paytm } from "../../Image/payment-svg/paytm.svg";
import {
  dispalyMoney,
  generateDiscountedPrice,
} from "../DisplayMoney/DisplayMoney";
import { useTheme } from "@emotion/react";
import PayPalIntegration from "../../constants/PayPal";

const useStyles = makeStyles((theme) => ({
  payemntPage: {
    padding: "1rem 0",
    width: "100%",
    backgroundColor: "white",
    overflow: "hidden",
  },

  paymentPage__container: {
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },

  PaymentBox: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0.5rem", // Corrected the property name
    overflow: "hidden", // Corrected the property name
    backgroundColor: "white",
    width: "50%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
      position: "fixed",
      bottom: 0,
      left: 0,
      marginTop: 0,
      boxShadow: '0 -2px 5px rgba(0,0,0,0.2)', // Add shadow to separate from content
    },
  },
  fixedButton: {
    display: 'block',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem',
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      position: 'fixed',
      bottom: 0,
      left: 0,
      // backgroundColor: theme.palette.background.paper,
      padding: '1rem',
      // boxShadow: '0 -2px 5px rgba(0,0,0,0.2)', // Add shadow to separate from content
    },
  },
  PaymentHeading: {
    fontWeight: "800",
    marginBottom: "1rem",
    fontSize: "1.5rem",
    textTransform: "uppercase",
  },
  securePayemnt: {
    display: "flex",
    alignItems: "center",
    fontWeight: "300",
    backgroundColor: "#f5f5f5 !important",
    width: "90%",
    padding: "1rem",
    gap: "0.8rem",
    marginBottom: "1rem",
    "& svg": {
      fontSize: "2rem",
    },
  },
  gridcheckbox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "3rem",
  },
  checkbox: {
    "& .MuiTypography-body1": {
      fontSize: "14px",
    },
    marginTop: theme.spacing(1),
    "& .MuiIconButton-label": {
      color: "black",
    },
  },
  rememberMeContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "10px",
    "& .MuiIconButton-label": {
      color: "black",
    },
  },
  order_Details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "2rem 0.5rem 2rem 0.5rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
    },
  },
  termsAndConditionsText: {
    fontFamily: "Asul",
    color: "#727272",
    fontWeight: "400",
    lineHeight: "17px",
    paddingLeft: "16px",
    fontSize: "12px",
  },
  privacyText: {
    marginLeft: "4px",
    textDecoration: "underline",
    color: "black",
    fontSize: "14px",
    "&:hover": {
      color: "#00aaa4",
    },
  },
  placeOrderBtn: {
    backgroundColor: "#000",
    color: "#fff",
    fontWeight: "500",
    fontSize: "1rem",
    padding: "0.8rem 1rem",
    borderRadius: "0.5rem",
    width: "100%",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#00aaa4",
    },
  },
  payemntAmount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "fit-content",
    padding: "1rem 0.5rem 0 0.5rem",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "2rem",
    },
  },
  orderSub_heading: {
    fontWeight: "600",
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  boldDivider: {
    borderBottom: `0.3px solid #3A3E3A`,
    margin: "5px 0",
    width: "99%",
  },
  shipping_Deatils: {
    display: "flex",
    flexDirection: "column",
    width: "98%",
    padding: "1rem 1px",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1rem 2rem",
    },
  },
  shipping_Address: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  shipping_Address_Details: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontWeight: "300",
    padding: "10px 0px",
    width: "70%",
  },
  shipping_Address_edit: {
    paddingRigth: "1rem",
    "& svg": {
      fontSize: "1.8rem",
      cursor: "pointer",
      color: "black",
      "&:hover": {
        color: "#00aaa4",
      },
    },
  },
  termsAndConditionsText: {
    fontFamily: "Asul",
    color: "#727272",
    textAlign: "center",
    lineHeight: "17px",
    paddingLeft: "4px",
    marginTop: theme.spacing(2),
    fontSize: "12px",
  },
  content: {
    paddingBottom: '6rem', // Space for fixed button on mobile
  },
}));

const PaymentComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const alert = useAlert();
  // const stripe = useStripe();
  // const elements = useElements();
  const dispatch = useDispatch();
  const { shippingInfo, cartItems, homeDelivery } = useSelector((state) => state.cart);
  const { user, loading } = useSelector((state) => state.userData);

  const { error } = useSelector((state) => state.newOrder);

  const subTotal = cartItems.reduce((acc, currItem) => {
    return acc + currItem.quantity * currItem.price;
  }, 0);
  const totalFinalPrice = subTotal;

  const address = `${shippingInfo.address} , ${shippingInfo.city} ${
    shippingInfo.state
  } , ${shippingInfo.pinCode} , ${shippingInfo.country || "Bulgaria"}`;

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: subTotal,
    shippingPrice: 0,
    totalPrice: totalFinalPrice,
    delivery: 0,
    user: user,
  };

  async function paymentSubmitHandler(paymentInfo = {}) {
    try {  
      window.fbq('track', 'Purchase', {
        content_ids: cartItems.map((item) => item.productId),
        currency: "BGN",
        value: totalFinalPrice,
        content_type: 'product',
      });
  
      order.paymentInfo = paymentInfo;
  
      dispatch(createOrder(order)); // Wait for the order to be created
  
      history.push("/success");
    } catch (error) {
      alert.error(error.message);
  }}  

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

  }, [dispatch, alert, error]);

  // claculte price after discount
  let totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );


  let discountedPrice = generateDiscountedPrice(totalPrice);
  let totalDiscount = totalPrice - discountedPrice;
  totalDiscount = dispalyMoney(totalDiscount); 
  totalPrice = dispalyMoney(totalPrice);

  return (
    <>
        <div className={classes.payemntPage}>
          <CheckoutSteps activeStep={2} />
          <MetaData title={"Payment"} />
          <div className={classes.paymentPage__container}>
            <div className={classes.payemntAmount}>
              <div className="order_summary">
                <h4>
                  Order &nbsp; ( {cartItems?.length}{" "}
                  {cartItems?.length > 1 ? "items" : "item"} )
                </h4>
                <br/>
                <div className="order_summary_details">
                  <div className="delivery order_Summary_Item">
                    <span>Price</span>
                    <p>
                      <b>{subTotal}€</b>
                    </p>
                  </div>
                  {/* <div className="discount order_Summary_Item">
                    <span>Discount</span>
                    <p>
                      <del>{totalDiscount}</del>
                    </p>
                  </div> */}

                  <div className="delivery order_Summary_Item">
                    <span>Shipping</span>
                    <p>
                      <b>Free</b>
                    </p>
                  </div>

                  <div className="separator_cart"></div>
                  <div className="total_price order_Summary_Item">
                    <div>
                      <h4 style={{ marginBottom: '5px'}}>Subtotal</h4>

                      <p
                        style={{
                          fontSize: "14px",
                          marginTop: "-10px",
                          color: "#414141",
                        }}
                      >
                        (VAT included)
                      </p>
                    </div>
                    <p>
                      <b>{totalFinalPrice}€</b>
                    </p>
                  </div>
                </div>
              </div>

              <div className="separator"></div>

              <div className={classes.order_Details}>
                <h5 className={classes.orderSub_heading}>ORDER DETAILS</h5>
                {cartItems &&
                  cartItems.map((item, idx) => (
                    <Link to={`/product/${item.productId}`} style ={{textDecoration : "none" , color : "inherit"}}>
                      <OrderDetailsSection
                        key={idx}
                        item={item}
                        totalDiscount={totalDiscount}
                        totalPrice={totalPrice}
                      />
                    </Link>
                  ))}
              </div>
              <Divider className={classes.boldDivider} />
              <div className={classes.shipping_Deatils}>
                <Typography variant="h6" className={classes.orderSub_heading}>
                  SHIPPING ADDRESS
                </Typography>

                <div className={classes.shipping_Address}>
                  <div className={classes.shipping_Address_Details}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: "16px", fontWeight: 400 }}
                    >
                      {user?.name && user?.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: "16px", fontWeight: 400 }}
                    >
                      {address}
                    </Typography>
                  </div>
                  <div className={classes.shipping_Address_edit}>
                    <EditIcon
                      className={classes.editIcon}
                      onClick={() => {
                        history.push("/shipping");
                      }}
                    />
                  </div>
                </div>
                <Typography
                  variant="subtitle2"
                  className={classes.mobileNo}
                  style={{
                    fontWeight: 400,
                    marginTop: "-5px",
                    fontSize: "16px",
                  }}
                >
                  {shippingInfo.phoneNo},
                </Typography>

                <Typography
                  variant="subtitle2"
                  className={classes.emailAddress}
                  style={{ fontWeight: 400, fontSize: "16px" }}
                >
                  {user?.email || shippingInfo?.email}
                </Typography>
              </div>

              <div className={classes.shipping_Deatils}>
                <Typography
                  variant="h6"
                  className={classes.orderSub_heading}
                  style={{ marginTop: "5px"}}
                >
                  DETAILS
                </Typography>
                <div className={classes.shipping_Address}>
                  <div className={classes.shipping_Address_Details}>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: "16px", fontWeight: 400 }}
                    >
                      {user?.name || shippingInfo?.firstName}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: "16px", fontWeight: 400 }}
                    >
                      {address}
                    </Typography>
                  </div>
                  <div className={classes.shipping_Address_edit}>
                    <EditIcon
                      className={classes.editIcon}
                      onClick={() => {
                        history.push("/shipping");
                      }}
                    />
                  </div>
                </div>
                <Typography
                  variant="subtitle2"
                  className={classes.mobileNo}
                  style={{
                    fontWeight: 400,
                    marginTop: "-5px",
                    fontSize: "16px",
                  }}
                >
                  {shippingInfo.phoneNo},
                </Typography>

                <Typography
                  variant="subtitle2"
                  className={classes.emailAddress}
                  style={{ fontWeight: 400, fontSize: "16px" }}
                >
                  {user?.email || shippingInfo?.email}
                </Typography>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                {/* <Grid container className={classes.rememberMeContainer}>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox onClick={toggleChecked} color="primary" />}
                      label={`When you make an order, you agree to our Terms & Conditions`}
                    />
                  </Grid>
                </Grid> */}
                  <Typography
                    variant="body2"
                    style={{ marginTop: "1rem", paddingBottom: "5vh" }}
                    className={classes.termsAndConditionsText}
                  >
                    When you make an order, you agree to ourz
                    <Link href="#" className={classes.privacyText}>
                      Terms & Conditions
                    </Link>
                </Typography>
                {/* <div className={classes.fixedButton}> */}
                
                <Button
                  variant="contained"
                  className={classes.placeOrderBtn}
                  onClick={() => paymentSubmitHandler()}
                >
                  Place Order
                </Button>
               
                <div>
                  <PayPalIntegration amount={totalFinalPrice} onSuccess={paymentSubmitHandler}/>
                </div>
              {/* </div> */}
              </div>
              

            </div>
          </div>
        </div>
    
    </>
  );
};

export default PaymentComponent;