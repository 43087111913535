import React, { useState } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PayPal from "../../../Image/Footer/PP.png";
import Cards from "../../../Image/Footer/cards.png";
import Email from "../../../Image/Footer/email.png";
import Whatsapp from "../../../Image/Footer/whatsapp.png";
import Telegram from "../../../Image/Footer/telegram.png";
import "./Footer.css";
const footMenu = [
  {
    id: 1,
    title: "Quick Links",
    menu: [
      {
        id: 1,
        link: "Account",
        path: "/account",
      },
      {
        id: 2,
        link: "Privacy Policy",
        path: "/gdpr",
      },

      // {
      //   id: 3,
      //   link: "Cancel Order",
      //   path: "/policy/return",
      // },
      {
        id: 4,
        link: "Orders",
        path: "/orders",
      },
      {
        id: 5,
        link: "Return Policy",
        path: "/policy/privacy",
      },
      {
        id: 6,
        link: "Delivery & Payment",
        path: "/terms/conditions",
      }      
    ],
  },
  // {
  //   id: 2,
  //   title: "Policies",
  //   menu: [
  //     {
  //       id: 1,
  //       link: "Return Policy",
  //       path: "/policy/return",
  //     },
  //     {
  //       id: 2,
  //       link: "Security",
  //       path: "/policy/privacy",
  //     },
  //     {
  //       id: 3,
  //       link: "Sitemap",
  //       path: "/policy/Terms",
  //     },
  //     {
  //       id: 4,
  //       link: "Privacy Policy",
  //       path: "/policy/privacy",
  //     },
  //     {
  //       id: 5,
  //       link: "T&C",
  //       path: "/terms/conditions",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Company",
  //   menu: [
  //     {
  //       id: 1,
  //       link: "About Us",
  //       path: "/about",
  //     },
  //     {
  //       id: 2,
  //       link: "Contact Us",
  //       path: "/contact",
  //     },
  //     {
  //       id: 3,
  //       link: "Service Centres",
  //       path: "/",
  //     },
  //     {
  //       id: 4,
  //       link: "Careers",
  //       path: "/",
  //     },
  //     {
  //       id: 5,
  //       link: "Affiliates",
  //       path: "/terms/conditions",
  //     },
  //   ],
  // },
];

 const footSocial = [
   {
     id: 1,
     icon: <FacebookIcon className="facebook_icon" fontSize="large" />,
     path: "https://www.facebook.com/profile.php?id=61565654966444",
   },
  //  {
  //    id: 2,
  //    icon: <TwitterIcon className="twitter_icon" fontSize="large" />,
  //    path: "https://twitter.com/Iam_DEv22",
  //  },
   {
     id: 3,
     icon: <InstagramIcon className="insta_icon" fontSize="large" />,
     path: "https://www.instagram.com/_the_sneaker_cave_",
   },
  //  {
  //    id: 4,
  //    icon: <LinkedInIcon className="likedin_icon" fontSize="large" />,
  //    path: "https://www.linkedin.com/in/iam-devesh/",
  //  },
 ];



const Footer = () => {
  const [subValue, setSubValue] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubValue("");
    alert("Thank you, you are subscribed to receive our daily newsletter");
  };

  const currYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="wrapper_footer footer_wrapper ">
            <div className="foot_about foot1">
              {/* <div className="foot_logo">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <img
                    style={{ width: "25vh" }}
                    src={require("../../../Image/3.png")}
                    alt="cricekt weapon logo"
                  />
                </Link>
              </div> */}

              {/* <div className="foot_subs">
                <h5>Newslatter</h5>
                <form onSubmit={handleSubmit} className="foot_form">
                  <input
                    type="email"
                    className="input_field_footer"
                    placeholder="Email Address*"
                    required
                    value={subValue}
                    onChange={(e) => setSubValue(e.target.value)}
                  />
                  <p>
                    By submitting your email address you agree to the{" "}
                    <Link to="/terms/conditions" className="foot_subs_text">
                      Terms & Conditions
                    </Link>
                  </p>
                  <button type="submit" className="btnFooter">
                    Subscribe
                  </button>
                </form>
              </div> */}
            </div>

            <div class="foot_menu_container">
              {footMenu.map((item) => {
                const { id, title, menu } = item;
                return (
                  <div class="foot_menu foot2" key={id}>
                    <h4>{title}</h4>
                    <div>
                      {menu.map((item) => {
                        const { id, link, path } = item;
                        return (
                          <li key={id}>
                            <Link to={path}>{link}</Link>
                          </li>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="foot_links foot3">
              <h4>Payment Methods</h4>
              <div className="foot_dowload_appLink">
                <div className="app_links">
                  <span className="googlePlayStore_link">
                    <a href="https://www.paypal.com/us/legalhub/useragreement-full">
                      <img style={{ width: 130, height: 'auto'}} src={PayPal} alt="play Store svg" />
                    </a>
                  </span>
                </div>
                <div className="app_links">
                  <span className="googlePlayStore_link">
                    <a href="https://www.paypal.com/us/legalhub/useragreement-full">
                      <img style={{ width: 180, height: 'auto'}} src={Cards} alt="play Store svg" />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="separatorFooter"></div>
          <div className="social-contaner">
              <a href="#">
                <img
                  src={require("../../../Image/1.png")}
                  alt="logo"
                  className="footerBottom__logo_main"
                />
              </a>
                <div>
                  <h4>Follow us!</h4>
                  <div className="social-container">
                    {footSocial.map((item) => {
                      const { id, icon, path } = item;
                      return (
                        <a
                          href={path}
                          key={id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {icon}
                        </a>
                      );
                    })}
                  </div> 
                </div>
                <div>
                  <h4>Contact us.</h4>
                  <div className="foot-social">
                    <a href="https://wa.me/447453347455" target="_blank">
                      <img style={{ width: 35, height: 'auto'}} src={Whatsapp} alt="play Store svg" />
                    </a>
                    <a href="https://t.me/+4fxybghXqF1iOTQ0">
                      <img style={{ width: 35, height: 'auto'}} src={Telegram} alt="Telegram:@TheSneakerCave" />
                    </a>
                    <a href="mailto:support@thesneakercave.com">
                      <img style={{ width: 37.5, height: 'auto'}} src={Email} alt="Support:support@thesneakercave.com" />
                    </a>
                  </div> 
                </div>
              </div>
        </div>

        <div className="separatorFooter"></div>

        <div className="sub_footer_root">
          <div className="container_Footer">
            <div className="sub_footer_wrapper">
              {/* <div className="foot_policyLink">
                <div>
                  <div className="subfoot_link_text1">
                    <Link to="/policy/privacy">
                      <p className="foot_policyLink_p">Return Policy</p>
                    </Link>
                  </div>
                  <div className="subfoot_link_text2">
                    <Link to="/terms/conditions">
                      <p className="foot_policyLink_p">Delivery & Payment</p>
                    </Link>
                  </div>
                  <div className="subfoot_link_text3">
                    <Link to="/about_us">
                      <p className="foot_policyLink_p">About Us</p>
                    </Link>
                  </div>
                </div>
              </div> */}

              <div className="foot_copyright">
                <p style={{ fontWeight: 'lighter'}}>8 Argall Ave, London E10 7QE, United Kingdom</p>
                <p>
                | The Sneaker Cave &copy;, All Rights Reserved {currYear} |
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
