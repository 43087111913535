import React from "react";
import { useAlert } from "react-alert";
import { PayPalButton } from "react-paypal-button-v2";

const PayPalIntegration = ({ onSuccess, amount }) => {
  const alert = useAlert();

  return (
    <div>
      <PayPalButton
        amount={amount}
        onSuccess={(details, data) => {
          console.log(details, data);
          const payment_info = {
            id: details.id,
            status: details.status,
            create_time: details.create_time,
          };
          onSuccess(payment_info); // Handle success with payment_info
        }}
        onError={() => {
          alert.error("There was an issue with your payment. Please try again.");
        }}
        currency="EUR"
        options={{
          clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
          intent: "capture", // Set intent to capture payment immediately
          commit: true, // Ensure payment is committed immediately
          disableFunding: 'credit,card', // Optionally disable certain funding sources
          locale: 'en_US', // Set locale for PayPal button
          debug: false, // Turn off debug mode for production
        }}
      />
    </div>
  );
};

export default PayPalIntegration;
