import React from "react";
import "./TermsAndCondtion.css";
import MetaData from "../component/layouts/MataData/MataData";

const TermsAndConditionsPage = () => {
  return (
    <div className="terms-container">
      <MetaData title="Delivery & Payment" />
      <div className="terms-overlay">
        <h1 className="terms-title">Delivery & Payment</h1>
      </div>
      <div className="terms-content">
        <h2>Shipping Information</h2>
        <p>
          Welcome to The Sneaker Cave! We’re thrilled that you’ve chosen us for your footwear needs. We know how important it is for you to receive your order quickly and securely, and we’re here to make that happen. Below, you’ll find all the essential information about our shipping process, timelines, and how to get in touch with us at any stage of your order.
        </p>
        <h3>Order Processing</h3>
        <ul>
          <li>
            <strong>Immediate Confirmation:</strong> Once your order is placed, you’ll receive an instant confirmation email. This means we’ve received your order and it’s in the queue for processing.
          </li>
          <li>
            <strong>Processing Timeline:</strong> We aim to process all orders within 1-3 business days. During this time, your order is verified, quality-checked, and carefully packed to ensure it arrives in pristine condition.
          </li>
          <li>
            <strong>Packaging Details:</strong> We use robust packaging solutions designed to protect your sneakers during transit, with eco-friendly materials wherever possible.
          </li>
        </ul>
        
        <h3>Delivery Time & Shipping Costs</h3>
        <ul>
          <li>
            <strong>Global Delivery:</strong> We offer free worldwide shipping, ensuring your sneakers reach you no matter where you are. The estimated delivery time varies depending on your location, typically ranging from 10 to 20 business days.
          </li>
          <li>
            <strong>Factors Influencing Delivery Time:</strong> While we strive to meet our delivery estimates, factors such as local customs processing, remote locations, or high-demand periods may affect delivery times. We’ll keep you updated if there are any delays.
          </li>
        </ul>

        <h3>Multiple Shipping Locations</h3>
        <ul>
          <li>
            <strong>Global Warehouses:</strong> We operate from several strategically located warehouses around the world. This allows us to ship your order from the nearest available location, reducing delivery time.
          </li>
          <li>
            <strong>Stock Availability:</strong> If the item you’ve ordered is out of stock at your closest warehouse, we’ll ship it from another location. While this may slightly extend delivery time, we ensure you’re informed every step of the way.
          </li>
        </ul>

        <h3>Real-Time Order Tracking</h3>
        <ul>
          <li>
            <strong>Tracking Information:</strong> Once your order is on its way, we’ll send you an email with your tracking number and a link to track your shipment. This allows you to follow your package every step of the way, from our warehouse to your doorstep.
          </li>
          <li>
            <strong>Support with Tracking:</strong> If you encounter any issues with tracking your order, or if you have any questions about its status, our customer support team is ready to assist you.
          </li>
        </ul>

        <h3>We’re Here for You—Contact Us Anytime</h3>
        <ul>
          <li>
            <strong>Open Communication:</strong> At The Sneaker Cave, we believe in maintaining an open line of communication with our customers. If you have any questions, concerns, or just want an update on your order, we’re here to help—anytime, anywhere.
          </li>
          <li>
            <strong>Immediate Assistance:</strong> Whether it’s a question about processing, tracking, or delivery, or if you just need reassurance that your order is on its way, don’t hesitate to reach out. We’re available via email at <a href="mailto:support@thesneakercave.com">support@thesneakercave.com</a>, or by phone at <a href="tel:+44 7453347455">+44 7453347455</a>.
          </li>
          <li>
            <strong>Order Not Received?</strong> If your order hasn’t arrived within 30 days from the dispatch date, please contact us immediately. We’ll launch an investigation and ensure the issue is resolved as quickly as possible, keeping you informed throughout the process.
          </li>
          <li>
            <strong>After-Hours Support:</strong> Even if it’s outside our normal business hours, you can still contact us via email or leave a message, and we’ll get back to you as soon as possible.
          </li>
        </ul>

        <h3>Additional Considerations</h3>
        <ul>
          <li>
            <strong>Customs and Duties:</strong> Depending on your location, your order may be subject to customs fees or import duties. These are determined by your local customs authority and are the responsibility of the customer. We recommend checking with your local customs office for more information.
          </li>
          <li>
            <strong>Returns and Exchanges:</strong> If you need to return or exchange your order, we offer a hassle-free process. Please visit our [Returns & Exchanges page] for detailed instructions and policies.
          </li>
          <li>
            <strong>Peace of Mind:</strong> Your satisfaction is our priority. From the moment you place your order to the moment it arrives at your door, we’re committed to providing you with the best possible service. Don’t hesitate to reach out at any time—we’re always here to help.
          </li>
        </ul>

        <h3>Secure Payments</h3>
        <p>
          We offer secure payment options through PayPal. You can pay using your PayPal account or with any major credit card (Visa, MasterCard, American Express) through PayPal’s secure payment gateway. Your payment information is encrypted and protected by industry-leading security measures, ensuring your purchase is safe with us.
        </p>
        <p>
          Thank you for choosing The Sneaker Cave. We’re excited to be a part of your sneaker journey, and we’re here to support you every step of the way!
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
